import convertToTL from "./price-converter";
import fixCategoryTree from "./category-tree-fixes";
import stickyBottomNav from "./sticky-bottom-nav";

var buyExchangeRate;
var sellExchangeRate;

var checkIsDOMCompletelyLoaded = () => {
  var onLoading = false;

  let checkDOM = setInterval(() => {
    let loadingImg = document.querySelectorAll(
      "img[src='pictures/loading.gif']"
    );
    if (onLoading) {
      if (!loadingImg.length) {
        DOMisCompletelyLoaded();
        onLoading = false;
      }
    } else {
      if (loadingImg.length) {
        onLoading = true;
      }
    }
  }, 50);
};



document.addEventListener("DOMContentLoaded", function () {
  // Create a media condition that targets viewports at least 768px wide
  const mediaQuery = window.matchMedia('(max-width: 768px)')

  // Check if the media query is true
  if (mediaQuery.matches) {
    let loginModule = document.querySelector("#login-module");
    let mobileLoginRegisterContainer = document.querySelector("#login-register-container");

    mobileLoginRegisterContainer.appendChild(loginModule);
  }



  // ***********auto close hamburger toggle***********
  setTimeout(() => {
    let categoryLinks = document.querySelectorAll(
      "#sidebar-categories-content a"
    );
    if (categoryLinks.length > 0) {
      categoryLinks.forEach((element) => {
        element.addEventListener("click", function categoryLinkClick(e) {
          setTimeout(() => {
            let targetElement = (e.target as HTMLElement).parentElement;
            let nestedList = targetElement.querySelector("ul:last-child");
            if (nestedList.childElementCount == 0) {
              let sidebar = document.querySelector(
                ".sidebar-left.hamburger-visible"
              );
              if (sidebar) {
                sidebar.classList.remove("hamburger-visible");
                document.querySelector(".sticky-bottom-nav > #hamburger-toggle-container").classList.remove("visited");
              }
            } else {
              nestedList.childNodes.forEach((child) => {
                child.addEventListener("click", categoryLinkClick);
              });
            }
          }, 300);
        });
      });
    }
  }, 1000);

  stickyBottomNav();
  checkIsDOMCompletelyLoaded();

  if (window.location.href.indexOf("kategori") !== -1) {
    let filter = document.querySelector("#filter-card");
    filter.className = "active";
  }

  let filters = document.querySelectorAll("#filter-card table");

  let stockFilterRows = filters[0].querySelectorAll("td");
  stockFilterRows[0].appendChild(stockFilterRows[1].firstElementChild);

  stockFilterRows[1].remove();

  let listAndGridFilterRows = filters[1].querySelectorAll("td");
  listAndGridFilterRows[0].appendChild(
    listAndGridFilterRows[1].firstElementChild
  );
  listAndGridFilterRows[1].appendChild(
    listAndGridFilterRows[2].firstElementChild
  );
  listAndGridFilterRows[1].appendChild(
    listAndGridFilterRows[3].firstElementChild
  );

  listAndGridFilterRows[2].remove();
  listAndGridFilterRows[3].remove();

  let newAndUsedFilterRows = filters[2].querySelectorAll("td");
  newAndUsedFilterRows[0].appendChild(
    newAndUsedFilterRows[1].firstElementChild
  );
  newAndUsedFilterRows[1].appendChild(
    newAndUsedFilterRows[2].firstElementChild
  );
  newAndUsedFilterRows[1].appendChild(
    newAndUsedFilterRows[3].firstElementChild
  );

  newAndUsedFilterRows[2].remove();
  newAndUsedFilterRows[3].remove();
});

window.addEventListener("hashchange", function () {
  let filter = document.querySelector("#filter-card");

  if (filter.className != "active") {
    if (window.location.href.indexOf("kategori") !== -1) {
      filter.className = "active";
    }
  } else if (window.location.href.indexOf("kategori") === -1) {
    filter.className = "";
  }
});

function DOMisCompletelyLoaded() {
  fixCategoryTree();

  let prices = document.querySelectorAll("#AnaIcerik .price span");

  prices.forEach((price) => {
    if (price.parentElement.classList.contains("buy-price-content")) {
      price.innerHTML = convertToTL(price, false);
    } else if (price.parentElement.classList.contains("sell-price-content")) {
      price.innerHTML = convertToTL(price, true);
    }
  });
}

// function convertToTL(targetPriceElement: Element, isSellPrice: boolean) {
//     let resultPrice: string;

//     if (targetPriceElement.innerHTML == "Sorunuz") {
//         return "Sorunuz";
//     } else {
//         let parent = targetPriceElement.parentElement;
//         let title = parent.getAttribute('title');

//         let parser = new DOMParser();
//         let doc = parser.parseFromString(title, "text/html");
//         if (!isSellPrice) {
//             resultPrice = doc.querySelector('tbody tr:last-child td:last-child span').innerHTML;
//         } else {
//             resultPrice = doc.querySelector('tbody tr:last-child td:nth-child(3) span').innerHTML;
//         }

//         return resultPrice;
//     }

// };
