export default () => {
  //merge categoryLink onclick with hit area onclick
  let hitAreas = document.querySelectorAll(
    "#sidebar-categories-content .hitarea"
  );
  hitAreas.forEach((area) => {
    let areaClone = area.cloneNode(true) as HTMLElement;

    area.parentNode.replaceChild(areaClone, area);

    let categoryLink = areaClone.parentElement.querySelector("a");

    areaClone.setAttribute("onclick", categoryLink.getAttribute("onclick"));
  });

};
