export default (targetPriceElement: Element, isSellPrice: boolean) => {
  let resultPrice: string;

  if (targetPriceElement.innerHTML == "Sorunuz") {
    return "Sorunuz";
  } else {
    let parent = targetPriceElement.parentElement;
    let title = parent.getAttribute("data-original-title");

    let parser = new DOMParser();
    let doc = parser.parseFromString(title, "text/html");
    if (!isSellPrice) {
      resultPrice = doc.querySelector("tbody tr:last-child td:last-child span")
        .innerHTML;
    } else {
      resultPrice = doc.querySelector(
        "tbody tr:last-child td:nth-child(3) span"
      ).innerHTML;
    }

    return resultPrice;
  }
};
